import { send } from "../index";

export function getHomeUserData(data, opts = {}) {
	//  仪表盘用户数据
  return send({
    url: "/admin/home/userData.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getHomeIncomeData(data, opts = {}) {
	//  仪表盘收入数据
  return send({
    url: "/admin/home/incomeData.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getHomeSeatData(data, opts = {}) {
	//  仪表盘观影人数
  return send({
    url: "/admin/home/ticketPersontime.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getExchangePublishReport(data, opts = {}) {
	//  兑换券发行报表
  return send({
    url: "/admin/coupon/issueStatistical.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardPublishReport(data, opts = {}) {
	//  年卡 次卡发行报表
  return send({
    url: "/admin/cardVolume/statement.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCardCumsumeReport(data, opts = {}) {
	// 兑换券 年卡 次卡消费报表
  return send({
    url: "/admin/cardConsume/statement.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function revenueSatement(data, opts = {}) {
	// 营收报表
  return send({
    url: "/admin/cinema/revenueSatement.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function revenueRefresh(data, opts = {}) {
	// 营收报表刷新进入数据
  return send({
    url: "/admin/statistics/revenueRefresh.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketOrderList(data, opts = {}) {
	// 影票报表
  return send({
    url: "/admin/orderBase/ticketOrderList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketOrderAfterSaleList(data, opts = {}) {
	// 影票售后列表
  return send({
    url: "/admin/orderAfterSale/afterTicketOrderList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketOrderRefund(data, opts = {}) {
	// 影票报表退款
  return send({
    url: "/admin/orderBase/ticketOrderRefund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketAgree(data, opts = {}) {
	// 影票售后报表同意
  return send({
    url: "/admin/orderAfterSale/ticketAgree.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketReject(data, opts = {}) {
	// 影票售后报表拒绝
  return send({
    url: "/admin/orderAfterSale/ticketReject.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketOrderRealtimeList(data, opts = {}) {
  // Yiivon: 实时影票明细报表
  return send({
    url: "/admin/orderBase/ticketOrderRealtimeList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function storeSalesStatistical(data, opts = {}) {
	// 商城销售明细表
  return send({
    url: "/admin/cinema/storeSalesStatistical.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cashTicketStatistics(data, opts = {}) {
	// 现金统计报表
  return send({
    url: "/admin/statistics/cashTicketStatistics.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function seatStatistics(data, opts = {}) {
	// 购票人数统计报表
  return send({
    url: "/admin/statistics/seatStatistics.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function seatStatisticsRealtime(data, opts = {}) {
  // Yiivon: 实时购票人数统计报表
  return send({
    url: "/admin/statistics/seatStatisticsRealtime.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cashTicketDetailStatistical(data, opts = {}) {
	// 微信购票支付明细报表
  return send({
    url: "/admin/cinema/cashTicketDetailStatistical.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function diffTicketStatistical(data, opts = {}) {
	// 补差明细报表
  return send({
    url: "/admin/cinema/diffTicketStatistical.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function snackStatisticsDetail(data, opts = {}) {
	// 卖品销售明细报表
  return send({
    url: "/admin/snackStatistics/snackStatisticsDetail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function snackStatisticsDetailRealtime(data, opts = {}) {
  // 卖品销售明细报表
  return send({
    url: "/admin/snackStatistics/snackStatisticsDetailRealtime.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function snackStatisticsVerification(data, opts = {}) {
	// 卖品核销明细报表
  return send({
    url: "/admin/snackStatistics/snackStatisticsVerification.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function filmDay(data, opts = {}) {
	// 电影日人数统计报表
  return send({
    url: "/admin/statistics/seatStatisticsByCinemaDay.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function scanRefund(data, opts = {}) {
	// 自定义付款退款
  return send({
    url: "/admin/orderAfterSale/scanRefund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function scanReport(data, opts = {}) {
	// 自定义付款报表
  return send({
    url: "/admin/paymentWithoutBill/reportList.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function ticketIncomeByFilm(data, opts = {}) {
  // 实时影片数据统计
  return send({
    url: "/admin/statistics/ticketIncomeByFilm.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketIncomeByCinema(data, opts = {}) {
  // 实时门店数据统计
  return send({
    url: "/admin/statistics/ticketIncomeByCinema.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function rechargeStatement(data, opts = {}) {
  // 储值卡充值报表
  return send({
    url: "/admin/rechargeRecord/rechargeStatement.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function rechargeStatementRefund(data, opts = {}) {
  // 储值卡充值退款
  return send({
    url: "/admin/rechargeRecord/refund.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function ticketOrderSeatList(data, opts = {}) {
  // 储值卡充值退款
  return send({
    url: "/admin/orderBase/ticketOrderSeatList.do",
    method: "POST",
    data,
    ...opts,
  });
}