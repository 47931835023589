import { send } from "../index";

export function getOrganizationList(data, opts = {}) {
	//  影院组织列表
  return send({
    url: "/admin/organization/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveOrganization(data, opts = {}) {
	//  添加影院组织
  return send({
    url: "/admin/organization/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateOrganization(data, opts = {}) {
	//  修改影院组织
  return send({
    url: "/admin/organization/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteOrganization(data, opts = {}) {
	//  删除影院组织
  return send({
    url: "/admin/organization/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaList(data, opts = {}) {
	//  影院列表
  return send({
    url: "/admin/cinema/list.do",
    method: "POST",
    data,
    ...opts,
  });
}
export function getCinemaAllList(data, opts = {}) {
  //  全部影院列表
  return send({
    url: "/admin/cinema/allList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaSync(data, opts = {}) {
	//  影院同步
  return send({
    url: "/admin/cinema/sync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaHallSync(data, opts = {}) {
	//  影厅同步
  return send({
    url: "/admin/cinemaHall/sync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullSchedules(data, opts = {}) {
	//  拉取影院排期
  return send({
    url: "/admin/cinemaSchedule/pullSchedules.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaDetail(data, opts = {}) {
	//  影院详情
  return send({
    url: "/admin/cinema/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaShareQR(data, opts = {}) {
	//  影院二维码
  return send({
    url: "/admin/cinema/shareQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPayQR(data, opts = {}) {
	//  影院自定义付款二维码
  return send({
    url: "/admin/cinema/payQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCinema(data, opts = {}) {
	//  修改影院
  return send({
    url: "/admin/cinema/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCinema(data, opts = {}) {
	//  新增影院
  return send({
    url: "/admin/cinema/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getHolidayPolicyList(data, opts = {}) {
	//  公共假期列表
  return send({
    url: "/admin/holidayPolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveHolidayPolicy(data, opts = {}) {
	//  新增公共假期
  return send({
    url: "/admin/holidayPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteHolidayPolicy(data, opts = {}) {
	//  刪除公共假期
  return send({
    url: "/admin/holidayPolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchDeleteHolidayPolicy(data, opts = {}) {
	//  批量刪除公共假期
  return send({
    url: "/admin/holidayPolicy/batchDelete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRefundPolicyList(data, opts = {}) {
	// 会员退票政策列表
  return send({
    url: "/admin/refundPolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveRefundPolicy(data, opts = {}) {
	// 会员退票政策 新增
  return send({
    url: "/admin/refundPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateRefundPolicy(data, opts = {}) {
	// 会员退票政策 修改
  return send({
    url: "/admin/refundPolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteRefundPolicy(data, opts = {}) {
	// 会员退票政策 删除
  return send({
    url: "/admin/refundPolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRefundPolicyDetail(data, opts = {}) {
	// 会员退票政策 详情
  return send({
    url: "/admin/refundPolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPricePolicyList(data, opts = {}) {
	// 场次定价 列表
  return send({
    url: "/admin/pricePolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function savePricePolicy(data, opts = {}) {
	// 场次定价 新增
  return send({
    url: "/admin/pricePolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updatePricePolicy(data, opts = {}) {
	// 场次定价 修改
  return send({
    url: "/admin/pricePolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deletePricePolicy(data, opts = {}) {
	// 场次定价 刪除
  return send({
    url: "/admin/pricePolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPricePolicyDetail(data, opts = {}) {
	// 场次定价 詳情
  return send({
    url: "/admin/pricePolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncPricing(data, opts = {}) {
	// 场次定价 同步
  return send({
    url: "/admin/pricePolicy/syncPricing.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmList(data, opts = {}) {
	// 影片列表
  return send({
    url: "/admin/cinema/filmList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFilmImg(data, opts = {}) {
	// 影片海报修改
  return send({
    url: "/admin/cinema/updateFilmImg.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getHallList(data, opts = {}) {
	// 影厅列表
  return send({
    url: "/admin/cinema/hallList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getScheduleList(data, opts = {}) {
	// 场次列表
  return send({
    url: "/admin/cinema/scheduleList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAreaList(data, opts = {}) {
	// 场区列表
  return send({
    url: "/admin/cinema/areaList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaCardGoodsList(data, opts = {}) {
	//  影院会员卡商品列表
  return send({
    url: "/admin/cinema/listV2.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCinemaCardGoods(data, opts = {}) {
	//  修改影院会员卡商品
  return send({
    url: "/admin/cinema/updateGoodsId.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaFilmModeList(data, opts = {}) {
	// 获取影片配置购票模式列表
  return send({
    url: "/admin/cinemaFilmMode/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaFilmModeDetail(data, opts = {}) {
	// 获取影片配置购票模式详情
  return send({
    url: "/admin/cinemaFilmMode/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCinemaFilmMode(data, opts = {}) {
	// 保存影片配置购票模式
  return send({
    url: "/admin/cinemaFilmMode/save.do",
    method: "POST",
    data,
    ...opts,
  });
}


export function updateCinemaFilmMode(data, opts = {}) {
	// 保存影片配置购票模式
  return send({
    url: "/admin/cinemaFilmMode/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function changeCinemaFilmMode(data, opts = {}) {
	// 影片配置购票模式启用禁用
  return send({
    url: "/admin/cinemaFilmMode/setIsDisabled.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteCinemaFilmMode(data, opts = {}) {
	// 影片配置购票模式删除
  return send({
    url: "/admin/cinemaFilmMode/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getOnlyFilmList(data, opts = {}) {
	// 获取影片列表 （没重复影片）
  return send({
    url: "/admin/cinema/onlyFilmList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullMovie(data, opts = {}) {
	// 拉取影院咨询平台信息
  return send({
    url: "/admin/cinemaFilm/pullMovie.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDomainName(data, opts = {}) {
  // 修改域名配置
  return send({
    url: "/admin/cinema/updateDomainName.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateReleaseInfo(data, opts = {}) {
	// 更新影院释放信息
  return send({
    url: "/admin/cinema/updateReleaseInfo.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getReleaseSeatsList(data, opts = {}) {
	// 自动动释放座位记录
  return send({
    url: "/admin/orderBase/releaseSeatsList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function batchReleaseSeat(data, opts = {}) {
	//
  return send({
    url: "/admin/cinema/batchReleaseSeat.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getBatchReleaseRecord(data, opts = {}) {
	// 手动释放座位
  return send({
    url: "/admin/operateLog/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}
