import { send } from "../index";

export function exportTask (data, opts = {}) {
	return send({
	  url: "/admin/taskExport/save.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function getExportList (data, opts = {}) {
	return send({
	  url: "/admin/taskExport/searchList.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function getImportList (data, opts = {}) {
	return send({
	  url: "/admin/taskImport/searchList.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}

export function getImportschedule(data, opts = {}) {
	return send({
	  url: "/admin/taskImport/schedule.do",
	  method: "POST",
	  data,
	  ...opts,
	});
}